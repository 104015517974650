<template>
	<div class="joblist">
		<h2>Ledige stillinger</h2>
		<ul>
			<li v-if="loading">
				<p>Laster...</p>
			</li>
			<li v-else-if="jobs.length === 0">
				<p>Det er for øyeblikket ingen ledige stillinger i Telenor Norge</p>
			</li>
			<li
				v-for="job in jobs"
				:key="job.url"
			>
				<a
					:href="job.url"
					target="_blank"
					>{{ job.title }}</a
				>
				<p>{{ job.functionName }}, {{ job.company }}</p>
			</li>
			<li>
				<a
					href="https://www.telenor.com/career/recruitment/vacant-positions/"
					target="_blank"
					rel="noopener noreferrer"
					>Se flere ledige stillinger i Telenor</a
				>
			</li>
		</ul>
	</div>
</template>

<script>
import GizmoComponentMixin from "~/mixins/GizmoComponentMixin.js";

export default defineNuxtComponent({
	name: "Joblist",
	mixins: [GizmoComponentMixin],

	data() {
		return {
			jobs: [],
			loading: true,
		};
	},

	async mounted() {
		try {
			this.loading = true;
			this.jobs = await $fetch("/api/jobs");
		} catch (err) {
			console.error(err);
		} finally {
			this.loading = false;
		}
	},

	computed: {
		model() {
			return this.content;
		},
	},
});
</script>

<style lang="scss" scoped>
.joblist {
	margin-bottom: 30px;

	h2 {
		margin-bottom: $spacing-m;

		@include font-title-xl;
	}

	p {
		font-family: $font-family-telenor-ui;
	}

	li {
		margin-bottom: 30px;

		@include font-text-m;

		a {
			color: $color-cta-default;
			font-weight: bold;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
</style>
